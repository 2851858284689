import { template as template_21b2e0eec7724dfa89b13819829feb96 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_21b2e0eec7724dfa89b13819829feb96(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
