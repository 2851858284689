import { template as template_587c92e9bf884fc19d243d7aca6f6f58 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_587c92e9bf884fc19d243d7aca6f6f58(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
