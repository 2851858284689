import { template as template_6acee22aaf324833ae40915701a36ee8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_6acee22aaf324833ae40915701a36ee8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
