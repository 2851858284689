import { template as template_87aa2faa425747d6967ad79ac9a4b43c } from "@ember/template-compiler";
const FKLabel = template_87aa2faa425747d6967ad79ac9a4b43c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
